import React from 'react';
import {css} from 'emotion';
import Styled from 'react-emotion';
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Row from './Row'
import DownloadAppButton from './appComponents/DownloadAppButton';
import DownloadAppButtonMobile from './appComponents/DownloadAppButtonMobile';


/* global tw */

const styles = {
    headerLogo: css`
        height: 3vh;
        @media (min-width: 992px){
            height: 7vh;
        }
    `,
    menuList: css`
        ${tw`list-reset block`};

    `,
    menuListItem: css`
        ${tw`hidden lg:inline-flex items-center lg:mx-4 relative `};

    `,
}

const NavBar = (props) => (
    <div className={css`${tw`w-screen overflow-hidden absolute pin-t py-4 z-100 bg-transparent`}`}>
    <div className={css`${tw`mx-auto -mt-2 max-w-row w-full flex items-center justify-between flex-wrap px-4 lg:px-1`}`}>
        
    <div className={css`${tw`flex items-center`}`}>
    <img className={styles.headerLogo}
         src={require('../assets/img/zyppys-logo.png')}
         alt="Zyppys Logo"/>
     <div className={css`${tw`text-white text-4xl font-extrabold tracking-wider pl-2`}`}>zyppys</div>
</div>


        <div className={css`${tw`flex items-center`}`}>
            <a href="tel:+918688901902" className={css`${tw`text-left lg:text-right text-white  tracking-tight no-underline opacity-80 text-1.2xl`}`}>
                Support :  +91  8688 901 902
            </a>

            <div className={css`${tw`ml-5`}`}>
                <DownloadAppButton light hideOnMobile/>
                <DownloadAppButtonMobile hide/>
            </div>
        </div>
    </div>
</div>



);


export default NavBar;